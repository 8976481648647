// Use This URL for testing
const URL = "vanavil.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "HDFC , SALEM MAIN",
  "Bank A/c. No.": "50200063790453",
  "IFSC Code": "HDFC 0000178 ",
};

export const weightUncertaintySupportiveValues = {
  barometer: 2.4,
  tempIndicator: 0.58,
  rhIndicator: 1.74,
  weightBox: 7950,
};

export const EccentricityDiagramInstruemntID = 0;
export const G_Value = 9.80665;

export const GST_NUMBER = "33AAICV5670J1ZZ";

export const DISABLED_MODULE =["Courier Register","History Card","Expenses","Master In/Out","Customer Complaints","Customer Feedback","Audit","Document Upload","Purchase"
,"DUC Master","Request Form","CoordinatesAddress","Client Complaints","Client Feedback","User","Attendance","MarkAttendance","CoordinatesAddress","Settings","Datasheet Template","OfflineMode","WCR Report","Department Analytics","Training Resources","Discipline","Assigned Duc","SRF_ULR_Register","Inward Register","External Calibration"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6";
